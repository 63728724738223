.Landing {
  width: 100vw;
  height: 100vh;
  background: #282c34;
  color: #d0d0d0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.title-card {
  padding: 20px;
  position: absolute;
  left: 25%;
  right: 25%;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.title-card > * {
  margin: 10px;
}

h1 {
  margin: 0;
} 
