* {
  @apply font-sans
}

.home {
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;

  background-color: #282c3e;
  color: white;
}

.messages {
  border-left: 1px solid #d0d0d0;
}

.sidebar-item {
  margin-bottom: 1rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  text-align: center;

  @apply cursor-pointer
}

.selected {
  background-color: #0088cc;
}

/* .message-form { */
  /* display: flex; */
  /* flex-direction: row; */
/* } */

/* .message-form > input { */
  /* width: 100%; */
  /* border-radius: 20px; */
  /* border: 1px solid black; */
  /* padding: 5px 10px; */
  /* flex: 0.95; */

  /* font-family: JetBrains Mono; */
  /* font-size: 15px; */
/* } */

/* .message-form > button { */
  /* background: #00000000; */
  /* color: white; */
  /* padding: 0; */
  /* flex: 0.05; */
/* } */

.message {
  padding: 5px 10px;
  margin: 5px;
  border-radius: 10px;
  background-color: #35757c;
}

.buttons > button {
  margin: 1px;
  flex: 0.5;
}
